import { expandAfterAutosize } from "components/DataGrid/helpers/expandAfterAutosize";

/**
 * Resizes all resizable columns in the grid to fit their contents and adjusts the grid width if necessary.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gridApi - The grid API object from ag-Grid.
 * @param {boolean} [params.skipHeader] - Whether to skip resizing the header row.
 * @param {boolean} [params.fillGridWidth=true] - Whether to fill any remaining grid width after auto-sizing columns.
 * @param {Object} [params.manuallyResizedColumns={}] - Object tracking manually resized columns.
 */
const autoSizeAll = ({
  gridApi,
  skipHeader,
  fillGridWidth = true,
  manuallyResizedColumns = {},
}) => {
  if (!gridApi) {
    console.warn("No grid API found");
    return;
  }
  const allColumnIds = gridApi.getColumns().map((column) => column.getId());
  const columnIdsToAutoSize = [];
  allColumnIds.forEach((colId) => {
    const column = gridApi.getColumnDef(colId);
    if (
      manuallyResizedColumns[colId]
      && column.actualWidth === manuallyResizedColumns[colId]
    ) {
      gridApi.setColumnWidth(colId, manuallyResizedColumns[colId]);
    } else if (
      column.resizable
      && !column.suppressAutoSize
      && !column.suppressSizeToFit
      && !manuallyResizedColumns[colId]
    ) {
      columnIdsToAutoSize.push(colId);
    }
  });

  gridApi.autoSizeColumns(columnIdsToAutoSize, skipHeader);

  if (fillGridWidth) {
    expandAfterAutosize(gridApi, manuallyResizedColumns);
  }
};

export default autoSizeAll;
