import { createElement, useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import MilestoneModal from "features/milestones/MilestoneModal";
import { milestonesColDefs } from "features/milestones/constants/milestonesColDefs";

const emptyArray = [];

const CreateMilestoneInstructions = () => {
  return (
    <p className="text-muted small mx-auto my-0" style={{ maxWidth: "24em" }}>
      To create a new milestone go to an Assignment page, then Milestones tab.
    </p>
  );
};

/**
 * Renders grid of creator Milestones with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.excludeDataSources]
 * @param {string} [props.gridId]
 * @param {Object} [props.filterParams]
 */
export const MilestonesGrid = ({
  filterParams = {},
  className,
  gridId = "",
  ...restProps
}) => {
  const {
    milestonesAssignment,
    isFetching: isFetchingAssignmentMilestones,
    isLoading: isLoadingAssignmentMilestones,
    refetch: refetchAssignment,
  } = apiSlice.useGetAssignmentQuery(
    { _id: filterParams?.assignment_id },
    {
      selectFromResult: ({ data, isError, isFetching, isLoading }) => ({
        milestonesAssignment: data?.milestones?.map((item) => {
          return {
            ...item,
            campaign_id: data?.campaign_id,
            assignment_id: data._id,
            user_id: data?.user_id,
            brand_id: data?.brand_id,
          };
        }),
        isError,
        isLoading,
        isFetching,
      }),
      skip: !filterParams?.assignment_id,
    },
  );
  const {
    milestonesMany,
    isFetching: isFetchingMilestones,
    isLoading: isLoadingMilestones,
    refetch: refetchMilestones,
  } = apiSlice.useGetAssignmentsQuery(filterParams, {
    selectFromResult: ({ data, isError, isFetching, isLoading }) => ({
      milestonesMany: data?.results?.flatMap((item) => {
        return item?.milestones?.map((milestone) => ({
          ...milestone,
          campaign_id: item?.campaign_id,
          assignment_id: item?._id,
          user_id: item?.user_id,
          brand_id: item?.brand_id,
        }));
      }),
      isError,
      isFetching,
      isLoading,
    }),
    skip: !!filterParams?.assignment_id,
  });

  const milestones = useMemo(() => {
    if (filterParams?.assignment_id) {
      return milestonesAssignment ?? emptyArray;
    }
    return milestonesMany ?? emptyArray;
  }, [filterParams?.assignment_id, milestonesAssignment, milestonesMany]);

  const refetchAll = useCallback(() => {
    refetchAssignment();
    refetchMilestones();
  }, [refetchAssignment, refetchMilestones]);

  const isFetching = isFetchingAssignmentMilestones || isFetchingMilestones;
  const isLoading = isLoadingAssignmentMilestones || isLoadingMilestones;
  const [activeMilestone_id, setActiveMilestone_id] = useState(null);
  const [activeAssignment_id, setActiveAssignment_id] = useState(null);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);

  const handleMilestoneClick = useCallback(
    ({ _id, assignment_id }) => {
      setActiveAssignment_id(assignment_id);
      setActiveMilestone_id(_id);
      setMilestoneModalOpen(!!_id);
    },
    [setActiveAssignment_id, setActiveMilestone_id, setMilestoneModalOpen],
  );
  const handleMilestoneClosed = useCallback(() => {
    setActiveAssignment_id(null);
    setActiveMilestone_id(null);
    setMilestoneModalOpen(false);
  }, [setActiveAssignment_id, setActiveMilestone_id, setMilestoneModalOpen]);

  const classes = clsx("milestones-grid", "h-100", className);
  const id = (gridId += ".milestones");

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        autoSizeIgnoreHeaders={false}
        borders={true}
        columnDefs={milestonesColDefs}
        gridId={id}
        height="100%"
        idField="_id"
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetchAll}
        quickFilter={true}
        rowData={milestones ?? []}
        actionText={"View/Pay"}
        actionClick={handleMilestoneClick}
        HeaderActionElem={createElement(CreateMilestoneInstructions)}
        {...restProps}
      />
      <MilestoneModal
        milestone_id={activeMilestone_id}
        assignment_id={activeAssignment_id}
        isOpen={milestoneModalOpen}
        setIsOpen={setMilestoneModalOpen}
        onModalClosed={handleMilestoneClosed}
      />
    </div>
  );
};
