import { useCallback, useMemo } from "react";

import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import { showToast } from "components/Toasts/helpers/showToast";
import { campaignDataDefs } from "features/campaigns/constants/campaignDataDefs";
import { getChangedValues } from "helpers/getChangedValues";

export const Campaign = ({
  campaign_id,
  isEditing,
  setIsEditing,
  disableEditing,
  ...props
}) => {
  const { data, isFetching } = apiSlice.useGetCampaignQuery({
    _id: campaign_id,
  });

  const [updateCampaign, { isLoading: isLoadingUpdate }]
    = apiSlice.useUpdateCampaignMutation();

  const isLoading = isFetching || isLoadingUpdate;

  const campaignDefs = useMemo(
    () => campaignDataDefs(data?.result),
    [data?.result],
  );
  const initialValues = useMemo(
    () => getInitialValues(campaignDefs?.body, data?.result),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaignDefs?.body, data?.result, isEditing],
  );

  const validate = useCallback(
    (values) => {
      const errors = {};

      campaignDefs?.body?.forEach((field) => {
        if (field?.editing?.required && !values[field?.fieldKey]) {
          errors[field?.fieldKey] = `Required`;
        }
        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.status) {
          errors.status = "Required";
        }
        if (!values.brand_id) {
          errors.brand_id = "Required";
        }
        if (values?.enableAccountSegmentation) {
          if (!values.department) {
            errors.department = "Required";
          }
          // FIXME: This is a temp fix for validation based on initial campaign data. Validation comparing against initial values which does not include shortId if the field is hidden I think. It should dynamically get required definition from datadefs.
          if (!values.shortCode && !data?.result?.shortId) {
            errors.shortCode = "Required";
          }
        }
        if (values.shortCode && !/^[a-zA-Z0-9]{3}$/.test(values.shortCode)) {
          errors.shortCode
            = "Short code must be exactly 3 characters and contain only letters and numbers";
        }

        if (
          initialValues?.enableAccountSegmentation
          && !values.enableAccountSegmentation
        ) {
          errors.enableAccountSegmentation
            = "Cannot disable account segmentation";
        }
      });

      return errors;
    },
    [
      campaignDefs?.body,
      data?.result?.shortId,
      initialValues?.enableAccountSegmentation,
    ],
  );

  const onSubmit = useCallback(
    async(values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        if (Object.keys(changedValues).length) {
          await updateCampaign({
            _id: data?.result?._id,
            ...changedValues,
          }).unwrap();
        }
        showToast({
          type: "success",
          message: "Campaign saved successfully.",
        });
        setIsEditing(false);
      } catch (error) {
        showToast({
          type: "error",
          message: `Error updating campaign. ${error?.data?.message || error?.message}`,
        });
        console.error("Error updating campaign", error);
      }
      actions.setSubmitting(false);
    },
    [data?.result?._id, initialValues, setIsEditing, updateCampaign],
  );

  return (
    <div className="campaign-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ dirty, isSubmitting, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true} borders={false}>
                {isLoading && <Loader />}

                {campaignDefs?.body?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <h2 className="fs-5 section-label text-muted">
                            {def?.label}
                          </h2>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={data?.result}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                      disabled={isSubmitting || !dirty}
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};
