import { Suspense, useEffect } from "react";

import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import Loader from "components/Loader";
import NavbarHeader from "components/NavbarHeader";
import { PreloadCreators } from "components/PreloadCreators";
import { getLoggedInUser } from "modules/Login/actions";

const PrivatePageLayout = ({ isAuthenticated, getLoggedInUser, ...props }) => {
  const navigate = useNavigate();
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <NavbarHeader />
      <main>
        <Suspense fallback={<Loader solid="true" />}>
          <Outlet />
          <PreloadCreators />
        </Suspense>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
  };
};
const ConnectedPrivatePageLayout = connect(mapStateToProps, {
  getLoggedInUser,
})(PrivatePageLayout);

export { ConnectedPrivatePageLayout as PrivatePageLayout };
