export const brandDataDefs = [
  {
    role: "section",
    fields: [
      {
        label: "Brand ID",
        fieldKey: "_id",
        type: "id",
        editing: false,
      },
      {
        label: "Brand/Company Name",
        fieldKey: "name",
        type: "string",
        // TODO: @segmentation review copy
        description:
          "The name of the company paying for campaigns (not the product's brand name).",
        editing: { required: true },
      },
      {
        label: "Short Code",
        fieldKey: "shortCode",
        type: "string",
        description:
          "A 3 character abbreviation for the brand. Must be unique. Used for constructing campaign short IDs.",
        editing: { required: true },
        // TODO: @segmentation require conditions, validation, custom component
      },
      {
        label: "Users",
        description:
          "The number of brand users able to log in to this brand's client portal.",
        fieldKey: "__brandUsers",
        type: "number",
        editing: false,
      },
      {
        label: "Created Date",
        fieldKey: "created",
        type: "date",
        editing: false,
      },
    ],
  },
  {
    label: "Campaign Metrics",
    role: "section",
    fields: [
      // TODO: add fields for other assignment counts (assignments, milestones)
      {
        label: "Campaigns",
        fieldKey: "__campaignBalances.count",
        type: "number",
        editing: false,
      },
      {
        label: "Invoiced",
        fieldKey: "__campaignBalances.invoicedAmount",
        type: "currency",
        editing: false,
      },
      {
        label: "Funded",
        fieldKey: "__campaignBalances.fundedAmount",
        type: "currency",
        editing: false,
      },
      {
        label: "Allocated",
        fieldKey: "__campaignBalances.allocatedBalance",
        type: "currency",
        editing: false,
      },
      {
        label: "Unallocated",
        fieldKey: "__campaignBalances.unallocatedBalance",
        type: "currency",
        editing: false,
      },
      {
        label: "Creator Budget",
        fieldKey: "__campaignBalances.creatorBudget",
        type: "currency",
        editing: false,
      },
      {
        label: "Paid to Creators",
        fieldKey: "__campaignBalances.amountPaidToCreators",
        type: "currency",
        editing: false,
      },
      {
        label: "SOW Amount",
        fieldKey: "__campaignBalances.sowAmount",
        type: "currency",
        editing: false,
      },
    ],
  },
];
