import { memo } from "react";

import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";

export const brandsColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "_id",
    cellRenderer: memo(BrandLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.name) {
        return "";
      }
      return `${params.data?.name} ${params.data?._id}`;
    },
  },
  // TODO: add columns for other assignment counts (assignments, milestones)
  {
    headerName: "Campaigns",
    children: [
      {
        headerName: "Count",
        field: "__campaignBalances.count",
        type: "count",
      },
      {
        headerName: "Invoiced",
        field: "__campaignBalances.invoicedAmount",
        type: "currency",
      },
      {
        headerName: "Funded",
        field: "__campaignBalances.fundedAmount",
        type: "currency",
      },
      {
        headerName: "Allocated",
        field: "__campaignBalances.allocatedBalance",
        type: "currency",
      },
      {
        headerName: "Unallocated",
        field: "__campaignBalances.unallocatedBalance",
        type: "currency",
      },
      {
        headerName: "Budget",
        field: "__campaignBalances.creatorBudget",
        type: "currency",
      },
      {
        headerName: "Paid to Creators",
        field: "__campaignBalances.amountPaidToCreators",
        type: "currency",
      },
      {
        headerName: "SOW Amount",
        field: "__campaignBalances.sowAmount",
        type: "currency",
      },
    ],
  },
  {
    headerName: "Brand Users",
    field: "__brandUsers",
    type: "count",
  },
  { headerName: "Created", field: "created", type: "date", sort: "desc" },
];
