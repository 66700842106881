import { useCallback, useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { Label } from "reactstrap";

import { useGetBrandQuery } from "app/apiSlice";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { Brand } from "features/brands/components";

export function BrandPage() {
  const { brand_id } = useParams();
  const { data } = useGetBrandQuery({ _id: brand_id });
  const displayedBrand = data?.result;

  const [isEditing, setIsEditing] = useState(false);

  const disableEditing = useCallback(
    (dirty) => {
      if (dirty) {
        const confirmDiscard = window.confirm("Discard unsaved changes?");
        if (confirmDiscard) {
          setIsEditing(false);
        }
      } else {
        setIsEditing(false);
      }
      // TODO: reset form values
    },
    [setIsEditing],
  );

  return (
    <div className="brand-page">
      <Panel key={brand_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/brands"} className="my-1">
          <h1 className="fs-2 d-flex align-items-center">
            <Label className="text-muted small">Brand:</Label>
            {displayedBrand?.name}
            {/* TODO: ask matt ot add update brand API endpoint, then i can implement brand editing */}
            {/* {displayedBrand?._id && !isEditing && (
              <Button
                color="secondary"
                outline
                size="sm"
                className="d-flex align-items-center ms-3"
                onClick={() => setIsEditing(true)}
              >
                <PencilIcon height={20} width={20} />
              </Button>
            )} */}
          </h1>
        </PanelHeader>
        <PanelBody split="vertical">
          {/* // TODO: @segmentation redo without using old BrandDetails component */}
          <Brand
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <Outlet />
        </PanelBody>
      </Panel>
    </div>
  );
}
