import { Field, ErrorMessage, useFormikContext } from "formik";
import { Label, Input, FormFeedback } from "reactstrap";

import CreatorVisibleIndicator from "./components/CreatorVisibleIndicator";
import Uploader from "./components/Uploader";

const CustomField = ({
  fieldKey,
  type = "",
  customType = "text",
  label = undefined,
  hideCondition = undefined,
  disableCondition = undefined,
  // TODO: refactor below, this is messy
  disableConditionCompareValues = {},
  hideConditionCompareValues = {},
  creatorVisible = undefined,
  fieldInputProps = {},
  ...props
}) => {
  const { values } = useFormikContext();
  let disabled = false;

  if (hideCondition && hideCondition(values, hideConditionCompareValues)) {
    return null;
  }
  if (
    disableCondition
    && disableCondition(values, disableConditionCompareValues)
  ) {
    disabled = true;
  }

  return (
    <>
      {label && (
        <Label className="text-muted" htmlFor={fieldKey}>
          {/* // TODO: This is used by milestone forms and any form where label is above field */}
          <CreatorVisibleIndicator
            isVisible={!!creatorVisible}
            showIcon={true}
            iconPosition="static"
          >
            {label}
          </CreatorVisibleIndicator>
        </Label>
      )}
      <Field name={fieldKey}>
        {({ field, form, meta }) => {
          const isInvalid = !!(meta.touched && meta.error);
          switch (type) {
            case "switch":
              return (
                <Input
                  type="switch"
                  id={fieldKey}
                  name={fieldKey}
                  checked={field?.value}
                  onChange={() => {
                    form.setFieldValue(field?.name, !field?.value);
                  }}
                  invalid={isInvalid}
                  disabled={disabled}
                />
                // TODO: currency input
              );
            case "uploader":
              return (
                <Uploader
                  name={fieldKey}
                  // @ts-ignore
                  accept={fieldInputProps?.accept}
                  // @ts-ignore
                  multiple={fieldInputProps?.multiple}
                  disabled={disabled}
                  invalid={isInvalid}
                />
              );
            case "custom":
              return (
                <props.CustomRenderer
                  type={customType}
                  name={fieldKey}
                  id={fieldKey}
                  fieldKey={fieldKey}
                  value={field?.value}
                  disabled={disabled}
                  invalid={isInvalid}
                  {...props}
                  {...field}
                />
              );
            default:
              return (
                <Input
                  type={type}
                  id={fieldKey}
                  name={fieldKey}
                  invalid={isInvalid}
                  disabled={disabled}
                  {...field}
                />
              );
          }
        }}
      </Field>
      <ErrorMessage
        name={fieldKey}
        render={(msg) => {
          let message = "";
          if (msg && typeof msg === "string") {
            message = msg;
          }
          return <FormFeedback invalid={"true"}>{message}</FormFeedback>;
        }}
      ></ErrorMessage>
    </>
  );
};

export default CustomField;
