import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { transfersColDefs } from "features/transfers/constants/transfersColDefs";

/**
 * Renders grid of creator Transfers with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.excludeDataSources]
 * @param {string} [props.gridId]
 * @param {Object} [props.filterParams]
 * @param {number} [props.filterParams.skip=0]
 * @param {number} [props.filterParams.limit=0]
 * @param {string} [props.filterParams.createdFrom]
 * @param {string} [props.filterParams.createdTo]
 * @param {string} [props.filterParams.stripeCustomerId] - Always use `stripeCustomerId` instead of `user_id` whenever possible
 * @param {string} [props.filterParams.user_id]
 * @param {string} [props.filterParams.campaign_id]
 * @param {string} [props.filterParams.brand_id]
 * @param {string} [props.filterParams.assignment_id]
 * @param {string} [props.filterParams.milestone_id]
 * @param {boolean} [props.filterParams.interweaveReversals]
 */
export const TransfersGrid = ({
  filterParams = {},
  className,
  gridId = "",
  ...restProps
}) => {
  const { data, isFetching, isLoading, refetch }
    = apiSlice.useGetTransfersQuery(filterParams);
  const classes = clsx("transfers-grid", "h-100", className);
  const id = (gridId += ".transfers");

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        autoSizeIgnoreHeaders={false}
        borders={true}
        columnDefs={transfersColDefs}
        gridId={id}
        height="100%"
        idField="id"
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        quickFilter={true}
        rowData={data?.results ?? []}
        {...restProps}
      />
    </div>
  );
};
