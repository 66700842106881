import { useMemo } from "react";

import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import { TransfersGrid } from "features/transfers";

export const CreatorTransfers = ({ creator_id, className, ...props }) => {
  const classes = clsx("creator-transfers", className);
  const { stripeCustomerId } = apiSlice.useGetCreatorQuery(
    { _id: creator_id },
    {
      selectFromResult: ({ data }) => ({
        stripeCustomerId: data?.result?.stripeCustomerId,
      }),
    },
  );
  const filterParams = useMemo(() => {
    return {
      stripeCustomerId,
      user_id: creator_id,
    };
  }, [creator_id, stripeCustomerId]);
  return (
    <TransfersGrid
      filterParams={filterParams}
      className={classes}
      gridId="creator-transfers"
    />
  );
};
