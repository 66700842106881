import { memo } from "react";

import CustomSelect from "components/Forms/CustomSelect";
import { titleCaseFormatter } from "helpers/formatters";
import BrandSelect from "modules/Brands/components/BrandSelect";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

const CampaignStatusInput = (props) => (
  <CustomSelect
    {...props}
    name="status"
    // TODO: default to waiting
    options={[
      // TODO: use unified campaign status enum dict
      { value: "active", label: "Active" },
      { value: "completed", label: "Completed" },
      { value: "paused", label: "Paused" },
      { value: "waiting", label: "Waiting" },
    ]}
  />
);

const DepartmentInput = (props) => (
  <CustomSelect
    {...props}
    name="department"
    options={[
      { value: "managed", label: "Managed Services" },
      { value: "talent", label: "Talent" },
    ]}
  />
);

export const campaignDataDefs = (displayedCampaign) => {
  return {
    header: {
      type: "string",
      label: "Campaign",
      value: displayedCampaign?.name,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            label: "Name",
            fieldKey: "name",
            type: "string",
            creatorVisible: true,
            hidden: true,
            editing: {
              required: true,
              hidden: false,
            },
          },
          {
            label: "Campaign ID",
            fieldKey: "_id",
            type: "id",
            editing: false,
          },
          {
            label: "Status",
            value: displayedCampaign?.status,
            fieldKey: "status",
            type: "select",
            CustomRenderer: memo(CampaignStatusBadge),
            editing: {
              required: true,
              CustomRenderer: memo(CampaignStatusInput),
            },
          },
          {
            label: "Brand",
            fieldKey: "brand_id?._id",
            displayValue: displayedCampaign?.brand_id?.name,
            type: "string",
            // CustomRenderer: memo(BrandLink),
            // componentProps: {
            //   _id: displayedCampaign?.brand_id?._id,
            //   data: displayedCampaign,
            // },
            editing: {
              required: true,
              fieldKey: "brand_id",
              disableCondition: () => {
                return (
                  displayedCampaign?.shortId
                  || displayedCampaign?.enableAccountSegmentation
                );
              },
              CustomRenderer: memo(BrandSelect),
              componentProps: {},
            },
          },
          {
            label: "Department",
            fieldKey: "department",
            type: "select",
            displayValue:
              displayedCampaign?.department === "managed"
                ? "Managed Services"
                : titleCaseFormatter(displayedCampaign?.department),
            editing: {
              disableCondition: () => {
                return (
                  displayedCampaign?.shortId
                  || displayedCampaign?.enableAccountSegmentation
                  || displayedCampaign?.department
                );
              },
              // should be required on all new campaigns. For existing campaigns, required if account segmentation is enabled, otherwise optional
              required:
                !displayedCampaign?._id
                || displayedCampaign?.enableAccountSegmentation,
              CustomRenderer: memo(DepartmentInput),
            },
          },
          {
            label: "Account Segmentation",
            fieldKey: "enableAccountSegmentation",
            type: "switch",
            editing: {
              label: "Account Segmentation",
              defaultValue: false,
              disableCondition: () => {
                // * backend validating for not being able to change this on legacy campaigns (created before segmentation features were released)
                return displayedCampaign?.enableAccountSegmentation;
                // ??? double check if segmentation required for talent campaigns or if backend validating for that
              },
            },
          },
          {
            label: displayedCampaign?._id ? "Short ID" : "Short Code",
            fieldKey: "shortCode",
            displayValue: displayedCampaign?.shortId,
            type: "id",
            description:
              "A unique abbreviation indicating the Department, Brand, and Campaign.",
            editing: {
              description: displayedCampaign?.shortId
                ? "A unique abbreviation indicating the Department, Brand, and Campaign."
                : "A 3 character abbreviation for the campaign. Must be unique across a given brand's campaigns. This will be appended to the Department and Brand abbreviations to form the full Short ID.",
              // type: "custom",
              // customType: "text",
              // CustomRenderer: memo(CampaignShortCodeInput),
              disableCondition: (values) => {
                return (
                  displayedCampaign?.shortId
                  || !values?.department
                  || !values?.brand_id
                );
              },
              hideCondition: (values) => {
                return (
                  !values?.enableAccountSegmentation
                  || displayedCampaign?.shortId
                );
              },
              required:
                displayedCampaign?.enableAccountSegmentation
                && !displayedCampaign?.shortId,
            },
          },
          {
            label: "Client Login Allowed",
            fieldKey: "allowClientAccess",
            type: "switch",
            editing: true,
          },
          {
            label: "Start Date",
            fieldKey: "startDate",
            type: "date",
            editing: true,
          },
          {
            label: "End Date",
            fieldKey: "endDate",
            type: "date",
            editing: true,
          },
          {
            label: "Created Date",
            fieldKey: "created",
            type: "date",
            editing: false,
          },
          {
            label: "Creators",
            value: displayedCampaign?.__assignmentBalances?.count,
            type: "number",
            editing: false,
          },
        ],
      },
      {
        role: "section",
        label: "Balances & Rates",
        fields: [
          {
            label: "SOW Amount",
            type: "currency",
            fieldKey: "sowAmount",
            editing: true,
          },
          {
            label: "Invoiced",
            type: "currency",
            fieldKey: "invoicedAmount",
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
          {
            label: "Funded",
            type: "currency",
            fieldKey: "fundedAmount",
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
          {
            label: "Allocated",
            fieldKey: "__assignmentBalances.allocatedBalance",
            type: "currency",
          },
          {
            label: "Unallocated",
            fieldKey: "__assignmentBalances.unallocatedBalance",
            type: "currency",
          },
          {
            label: "Creator Budget",
            type: "currency",
            fieldKey: "creatorBudget",
            editing: true,
          },
          {
            label: "Paid to Creators",
            type: "currency",
            value: displayedCampaign?.amountPaidToCreators,
          },
          {
            label: "Hold",
            value: displayedCampaign?.__assignmentBalances?.holdBalance,
            type: "currency",
          },
        ],
      },
    ],
  };
};
