import { useCallback, useMemo } from "react";

import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import { showToast } from "components/Toasts/helpers/showToast";
import { creatorDataDefs } from "features/creators/constants/creatorDataDefs";
import { getChangedValues } from "helpers/getChangedValues";
import isValidEmail from "helpers/isValidEmail";

export const Creator = ({
  creator_id,
  isEditing,
  setIsEditing,
  disableEditing,
  ...props
}) => {
  const { data } = apiSlice.useGetCreatorQuery({
    _id: creator_id,
  });

  const [updateCreator] = apiSlice.useUpdateCreatorMutation();

  const creatorDefs = useMemo(
    () => creatorDataDefs(data?.result),
    [data?.result],
  );
  const initialValues = useMemo(
    () => getInitialValues(creatorDefs?.body, data?.result),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creatorDefs?.body, data?.result, isEditing],
  );

  const validate = useCallback(
    (values) => {
      const errors = {};
      const fieldDefs = creatorDefs?.body;
      fieldDefs?.forEach((section) => {
        if (section?.role === "section") {
          section?.fields?.forEach((field) => {
            if (
              field?.editing?.required
              && (values[field?.fieldKey] === undefined
                || values[field?.fieldKey] === null)
            ) {
              errors[field?.fieldKey] = `This field is required`;
            }

            if (
              field?.type === "email"
              && !isValidEmail(values[field?.fieldKey])
            ) {
              errors[field?.fieldKey] = "Must be a valid email address";
            }

            if (
              field?.type === "number"
              && (!values[field?.fieldKey] || values[field?.fieldKey] <= 0)
            ) {
              errors[field?.fieldKey] = `Must be an amount greater than 0`;
            }
          });
        }
      });
      return errors;
    },
    [creatorDefs?.body],
  );

  const onSubmit = useCallback(
    async(values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        if (Object.keys(changedValues).length) {
          await updateCreator({
            _id: data?.result?._id,
            ...changedValues,
          }).unwrap();
        }
        showToast({
          type: "success",
          message: "Creator saved successfully.",
        });
        setIsEditing(false);
      } catch (error) {
        showToast({
          type: "error",
          message: `Error saving creator. ${error?.data?.message || error?.message}`,
        });
        console.error("Error saving creator", error);
      }
      actions.setSubmitting(false);
    },
    [data?.result?._id, initialValues, setIsEditing, updateCreator],
  );

  return (
    <div className="creator-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true}>
                {creatorDefs?.body?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <h2 className="fs-5 section-label text-muted">
                            {def?.label}
                          </h2>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={data?.result}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};
