import { Badge } from "reactstrap";

export const GridRowCountIndicator = ({
  isLoading,
  isFetching,
  filteredCount,
}) => {
  let text = "Loading...";
  let color = "secondary";
  if (isLoading) {
    text = "Loading...";
    color = "secondary";
  } else if (isFetching) {
    text = "Refetching data...";
    color = "info";
  } else if (filteredCount != null) {
    text = `${filteredCount} items`;
    color = "secondary";
  } else {
    text = "No data";
    color = "secondary";
  }

  return (
    <Badge color={color} pill>
      <span className="data-grid__footer__data__row-count__count">{text}</span>
    </Badge>
  );
};
