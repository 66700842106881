import { useCallback, useRef, useState } from "react";

import { Field, useField } from "formik";
import "./index.scss";
import { Label } from "reactstrap";
import {
  MagnifyingGlassPlusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import CreatorVisibleIndicator from "components/Forms/components/CreatorVisibleIndicator";
import PreviewImageModal from "components/ImageUploader/PreviewImageModal";
import { showToast } from "components/Toasts/helpers/showToast";

const ImageUploader = ({
  disabled = false,
  label = undefined,
  creatorVisible = undefined,
  ...props
}) => {
  const [imageUrlsField, _imageUrlsMeta, imageUrlsHelpers]
    = useField("imageUrls");
  const [uploadedImagesField, _uploadedImagesMeta, uploadedImagesHelpers]
    = useField("uploadedImages");

  const [expandedImageModalOpen, setExpandedImageModalOpen] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);

  const defaultFileInputRef = useRef(null);

  const toggleExpandedImageModal = () =>
    setExpandedImageModalOpen((prevIsOpen) => !prevIsOpen);

  const handlePreviewImageClick = (image) => {
    setExpandedImage(image);
    setExpandedImageModalOpen(true);
  };

  const handleUploadClick = () => defaultFileInputRef.current.click();

  const handleFileChange = async(event) => {
    try {
      const files = event.currentTarget.files;
      const fileArray = Array.from(files);
      const newVal = [...uploadedImagesField.value, ...fileArray];
      uploadedImagesHelpers.setValue(newVal, false);
    } catch (error) {
      showToast({
        type: "error",
        message: "Error uploading image",
      });
    }
  };

  const handleRemoveImage = useCallback(
    ({ index, type }) => {
      if (type === "existing") {
        const newImageUrls = [...imageUrlsField.value];
        newImageUrls.splice(index, 1);
        imageUrlsHelpers.setValue(newImageUrls);
      }
      if (type === "uploaded") {
        const newUploadedImages = [...uploadedImagesField.value];
        newUploadedImages.splice(index, 1);
        uploadedImagesHelpers.setValue(newUploadedImages);
      }
    },
    [
      imageUrlsField.value,
      imageUrlsHelpers,
      uploadedImagesField.value,
      uploadedImagesHelpers,
    ],
  );

  return (
    <div className="image-uploader">
      <PreviewImageModal
        isOpen={expandedImageModalOpen}
        setIsOpen={setExpandedImageModalOpen}
        toggle={toggleExpandedImageModal}
        image={expandedImage}
        returnFocusAfterClose={false}
      />
      {label && (
        <Label className="text-muted" htmlFor={"images"}>
          <CreatorVisibleIndicator
            isVisible={!!creatorVisible}
            showIcon={true}
            iconPosition="static"
          >
            {label}
          </CreatorVisibleIndicator>
        </Label>
      )}
      <Field
        name="images"
        type="file"
        accept="image/*"
        multiple
        innerRef={defaultFileInputRef}
        onChange={handleFileChange}
        className="file-input-hidden visually-hidden"
      />
      <Field name={"images"}>
        {() => {
          return (
            <div className="thumbnails">
              {imageUrlsField?.value?.map((image, index) => (
                <div className="thumbnail" key={`existing-${index}`}>
                  <button
                    type="button"
                    className="expand-image-btn"
                    aria-label="View larger image"
                    onClick={() => handlePreviewImageClick(image)}
                  >
                    <img
                      src={image}
                      alt="Thumbnail of uploaded content"
                      className="img-fluid"
                    />
                    <MagnifyingGlassPlusIcon width="24px" height="24px" />
                  </button>
                  {!disabled && (
                    <button
                      type="button"
                      className="remove-image-btn"
                      aria-label="Remove image"
                      title="Remove image"
                      color="danger"
                      onClick={() => {
                        handleRemoveImage({ index, type: "existing" });
                      }}
                    >
                      <XMarkIcon width="15px" height="15px" strokeWidth={2.5} />
                    </button>
                  )}
                </div>
              ))}
              {uploadedImagesField?.value?.map((image, index) => (
                <div key={`uploaded-${index}`}>
                  <button
                    type="button"
                    className="expand-image-btn"
                    aria-label="View larger image"
                    onClick={() => handlePreviewImageClick(image)}
                  >
                    <img
                      // TODO: do not create a new objectURL on every render
                      src={URL.createObjectURL(image)}
                      alt="Thumbnail of uploaded content"
                      className="img-fluid"
                    />
                    <MagnifyingGlassPlusIcon width="24px" height="24px" />
                  </button>
                  {!disabled && (
                    <button
                      type="button"
                      className="remove-image-btn"
                      aria-label="Remove image"
                      title="Remove image"
                      onClick={() => {
                        handleRemoveImage({ index, type: "uploaded" });
                      }}
                    >
                      <XMarkIcon width="15px" height="15px" />
                    </button>
                  )}
                </div>
              ))}
              {!disabled && (
                <button
                  type="button"
                  aria-label="Upload images"
                  title="Upload images"
                  onClick={handleUploadClick}
                  className="upload-photo-btn"
                >
                  <PlusIcon
                    width="26px"
                    height="26px"
                    role="img"
                    focusable="false"
                  />
                </button>
              )}
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default ImageUploader;
