/**
 * Resizes resizable columns in the grid to take up any remaining grid width if necessary,
 * @param {Object} gridApi - The grid API object from ag-Grid.
 */
export const expandAfterAutosize = (gridApi, manuallyResizedColumns) => {
  if (!gridApi) {
    console.error("expandAfterAutosize: gridApi is required");
    return;
  }
  const allDisplayedColumns = gridApi.getAllDisplayedColumns();
  if (!allDisplayedColumns.length) {
    console.error("expandAfterAutosize: No displayed columns found");
    return;
  }
  const gridElement = gridApi?.gridBodyCtrl?.eBodyViewport;
  if (!gridElement) {
    console.error("expandAfterAutosize: Grid element not found");
    return;
  }
  const gridWidth = gridElement.offsetWidth;
  let totalColumnWidth = 0;
  const resizableColumnsInfo = [];
  allDisplayedColumns.forEach((column) => {
    const colWidth = column.getActualWidth();
    totalColumnWidth += colWidth;
    const colDef = column.getColDef();
    if (
      colDef?.resizable
      && !colDef?.suppressSizeToFit
      && !colDef?.suppressAutoSize
      && !manuallyResizedColumns[column.getColId()]
    ) {
      resizableColumnsInfo.push({ colId: column.getColId(), colWidth });
    }
  });
  const remainingWidth = gridWidth - totalColumnWidth;
  if (remainingWidth <= 0 || resizableColumnsInfo.length === 0) return;
  const totalResizableWidth = resizableColumnsInfo.reduce(
    (sum, col) => sum + col.colWidth,
    0,
  );
  if (totalResizableWidth === 0) return;
  const scalingFactor
    = (totalResizableWidth + remainingWidth) / totalResizableWidth;
  if (scalingFactor <= 1.01) return; // Avoid resizing if the difference is negligible
  resizableColumnsInfo.forEach(({ colId, colWidth }) => {
    const newWidth = colWidth * scalingFactor;
    gridApi.setColumnWidth(colId, newWidth);
  });
};
