import { useMemo } from "react";

import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import { TransfersGrid } from "features/transfers";

export const AssignmentTransfers = ({ assignment_id, className, ...props }) => {
  const classes = clsx("assignment-transfers", className);
  const { stripeCustomerId } = apiSlice.useGetAssignmentQuery(
    { _id: assignment_id },
    {
      selectFromResult: ({ data }) => ({
        stripeCustomerId: data?.user_id?.stripeCustomerId,
      }),
    },
  );
  const filterParams = useMemo(() => {
    return {
      stripeCustomerId,
      assignment_id: assignment_id,
    };
  }, [assignment_id, stripeCustomerId]);
  return (
    <TransfersGrid
      filterParams={filterParams}
      className={classes}
      gridId="assignment-transfers"
      excludeDataSources="assignment"
    />
  );
};
