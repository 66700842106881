import { useEffect } from "react";

/**
 * A custom React hook that shows or hides a loading overlay on a DataGrid component based on the loading state.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.gridApi - The grid API object from ag-Grid.
 * @param {boolean} params.isLoading - Additional loading state (unused in the current implementation).
 */
export const useLoadingOverlay = ({ gridApi, isLoading }) => {
  useEffect(() => {
    if (!gridApi) return;
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);
};
