import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { CreateCampaignModalButton } from "features/campaigns/components";
import { campaignColDefs } from "features/campaigns/constants/campaignsColDefs";

// TODO move this component elsewhere. I'm just putting this here for now since I'm doing the brand refactor

/**
 * Fetches campaigns based on the provided parameters.
 *
 * @param {{
 *   className?: string;
 *   filterParams?: {
 *     skip?: number;
 *     limit?: number;
 *     sortKey?: string;
 *     sortValue?: number;
 *     createdFrom?: string;
 *     createdTo?: string;
 *     status?: string;
 *     brand_id?: string;
 *     allowContentApproval?: boolean;
 *     allowClientAccess?: boolean;
 *     populate?: string;
 *     calculate?: string;
 *   };
 * } & Record<string,any>} props
 */
export const CampaignsGrid = ({
  filterParams = {},
  className = "",
  gridId = "",
  ...restProps
}) => {
  const { data, isFetching, isLoading, refetch }
    = apiSlice.useGetCampaignsQuery(filterParams);
  const classes = clsx("campaigns-grid", "h-100", className);
  const id = (gridId += ".campaigns");

  return (
    <div className={classes}>
      <DataGrid
        gridId={id}
        borders={true}
        idField="_id"
        rowData={data?.results ?? []}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        columnDefs={campaignColDefs}
        quickFilter={true}
        appliedFilters={true}
        // @ts-ignore
        height="100%"
        showLinkButton={true}
        linkButtonTo="/campaigns/:_id"
        HeaderActionElem={
          <CreateCampaignModalButton initialBrand_id={filterParams?.brand_id} />
        }
        {...restProps}
      />
    </div>
  );
};
