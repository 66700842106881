import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useGetBrandQuery, useUpdateBrandMutation } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import { brandDataDefs } from "features/brands/constants/brandDataDefs";
import { getChangedValues } from "helpers/getChangedValues";

export const Brand = ({
  isEditing,
  setIsEditing,
  disableEditing,
  ...props
}) => {
  const { brand_id } = useParams();
  const { data, isFetching } = useGetBrandQuery({
    _id: brand_id,
  });
  const displayedBrand = data?.result || [];
  const initialValues = getInitialValues(brandDataDefs, displayedBrand);
  const [updateBrand, { isLoading: isLoadingUpdate }]
    = useUpdateBrandMutation();

  const isLoading = isFetching || isLoadingUpdate;

  const onSubmit = useCallback(
    async(values, actions) => {
      const changedValues = getChangedValues(values, initialValues);
      if (Object.keys(changedValues).length) {
        try {
          await updateBrand({
            _id: displayedBrand?._id,
            ...changedValues,
          }).unwrap();
          setIsEditing(false);
        } catch (error) {
          console.error(error);
        }
      }
      actions.setSubmitting(false);
    },
    [displayedBrand?._id, initialValues, setIsEditing, updateBrand],
  );

  return (
    <div className="creator-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // validate={(values) => {
        //   // TODO: validate
        // }}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true} borders={false}>
                {isLoading && <Loader />}

                {brandDataDefs?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <h2 className="fs-5 section-label text-muted">
                            {def?.label}
                          </h2>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={data?.result}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};
