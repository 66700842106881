import { useState } from "react";

// TODO: @datagrid use context instead
export const useGridApis = () => {
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    if (!params?.api || !params?.columnApi) return;
    setGridApi(params.api);
  };

  return { gridApi, onGridReady };
};
