export const mergeEventHandlers = (events) => {
  const acc = {};
  events.forEach((event) => {
    Object.entries(event).forEach(([key, func]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(func);
    });
  });

  const merged = {};
  Object.keys(acc).forEach((event) => {
    merged[event] = (...args) => {
      acc[event].forEach((func) => func(...args));
    };
  });

  return merged;
};
