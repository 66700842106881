import clsx from "clsx";

import { useGetBrandUsersQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { CreateBrandUserModalButton } from "modules/Brands/components/CreateBrandUserModalButton";
import { brandUsersColDefs } from "modules/Brands/constants";

// TODO move this component elsewhere. I'm just putting this here for now since I'm doing the brand refactor
/**
 * Renders grid of brand invoices with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.gridId]
 * @param {Object} [props.filterParams]
 * @param {string} [props.filterParams.brand_id] - Filter records by brand ID.
 * @param {number} [props.filterParams.skip] - Number of records to skip.
 * @param {number} [props.filterParams.limit] - Maximum number of records to return.
 * @param {string} [props.filterParams.sortKey] - Key to sort the records by.
 * @param {number} [props.filterParams.sortValue] - Value to sort the records by.
 * @param {string} [props.filterParams.createdFrom] - Filter records created from this date.
 * @param {string} [props.filterParams.createdTo] - Filter records created to this date.
 */
export const BrandUsersGrid = ({ filterParams, className, gridId = "" }) => {
  const { data, isFetching, isLoading, refetch }
    = useGetBrandUsersQuery(filterParams);
  const classes = clsx("brand-users-grid", "h-100", className);
  const id = (gridId += ".brandusers");

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        borders={true}
        columnDefs={brandUsersColDefs}
        gridId={id}
        height="100%"
        idField="_id"
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        quickFilter={true}
        rowData={data?.results ?? []}
        showLinkButton={true}
        linkButtonTo=":_id"
        HeaderActionElem={<CreateBrandUserModalButton />}
      />
    </div>
  );
};
