import { useCallback, useEffect, useRef, useState } from "react";

import autoSizeAll from "components/DataGrid/helpers/autoSizeAll";
import debounce from "helpers/debounce";

const storageKey = `v4-grid-state`;

/**
 * Custom hook for persisting and managing grid state
 * @param {Object} options - The options for the hook
 * @param {Object} options.gridApi - The grid API object
 * @param {string} options.gridId - The unique identifier for the grid
 * @param {boolean} options.autoSizeEnabled - Whether auto-sizing is enabled
 * @param {boolean} options.autoSizeIgnoreHeaders - Whether to ignore headers when auto-sizing
 * @returns {Object} An object containing grid state management functions and properties
 */
export const usePersistGridState = ({
  gridApi,
  gridId,
  autoSizeEnabled,
  autoSizeIgnoreHeaders,
}) => {
  const [manuallyResizedColumns, setManuallyResizedColumns] = useState({});
  const [sizingRestored, setSizingRestored] = useState(false);

  const debouncedAutosizeRef = useRef(null);
  const debouncedFitColumnsRef = useRef(null);

  /**
   * Saves the current grid state to local storage
   */
  const saveGridState = useCallback(
    (manualColumns = manuallyResizedColumns) => {
      try {
        if (!gridApi) {
          throw new Error(`GridApi is not available for ${gridId}`);
        }
        const columnState = gridApi.getColumnState();
        const filterModel = gridApi.getFilterModel();
        const savedState = {
          columnState,
          filterModel,
          manuallyResizedColumns: manualColumns,
        };
        const savedGridStates
          = JSON.parse(localStorage.getItem(storageKey)) || {};
        savedGridStates[gridId] = savedState;
        localStorage.setItem(storageKey, JSON.stringify(savedGridStates));
      } catch (error) {
        console.error(`Error saving grid state for ${gridId}`, { error });
      }
    },
    [gridApi, gridId, manuallyResizedColumns],
  );

  const handleSaveStateChange = useCallback(
    (event) => {
      if (event.source !== "api") {
        saveGridState();
      }
    },
    [saveGridState],
  );

  /**
   * Restores the grid state from local storage
   * @param {Object} api - The grid API object
   */
  const restoreGridState = useCallback(
    (api) => {
      try {
        if (!api) {
          throw new Error(`GridApi is not available for ${gridId}`);
        }
        const savedGridStates
          = JSON.parse(localStorage.getItem(storageKey)) || {};
        const savedState = savedGridStates[gridId];
        if (savedState) {
          api.applyColumnState({
            state: savedState.columnState,
            applyOrder: true,
          });
          api.setFilterModel(savedState.filterModel);
        }
      } catch (error) {
        console.error(`Error restoring grid state for ${gridId}`, { error });
      }
    },
    [gridId],
  );

  const restoreGridSizing = useCallback(
    (api) => {
      try {
        if (!api) {
          throw new Error(`GridApi is not available for ${gridId}`);
        }
        const savedGridStates
          = JSON.parse(localStorage.getItem(storageKey)) || {};
        const savedState = savedGridStates[gridId];
        let tempStoreManualCols = {};
        if (savedState) {
          tempStoreManualCols = savedState.manuallyResizedColumns || {};
          setManuallyResizedColumns(() => {
            setSizingRestored(true);
            return tempStoreManualCols;
          });
        }
        if (autoSizeEnabled) {
          autoSizeAll({
            gridApi: api,
            skipHeader: autoSizeIgnoreHeaders,
            manuallyResizedColumns: tempStoreManualCols,
          });
        }
      } catch (error) {
        console.error(`Error restoring grid sizing for ${gridId}`, { error });
      }
    },
    [autoSizeEnabled, autoSizeIgnoreHeaders, gridId],
  );

  /**
   * Handles the grid ready event
   * @param {Object} params - The event parameters
   */
  const onGridReady = useCallback(
    (params) => {
      if (!params.api) return;
      restoreGridState(params.api);
    },
    [restoreGridState],
  );
  /**
   * Handles the first data rendered event
   * @param {Object} params - The event parameters
   */
  const onFirstDataRendered = useCallback(
    (params) => {
      if (!params.api) return;
      restoreGridSizing(params.api);
    },
    [restoreGridSizing],
  );

  /**
   * Handles the column resized event
   * @param {Object} event - The column resized event
   */
  const onColumnResized = useCallback(
    (event) => {
      if (event.finished && event.source === "uiColumnResized") {
        const newManuallyResizedColumns = { ...manuallyResizedColumns };
        event.columns.forEach((column) => {
          // TODO: colId may already be available in event data
          newManuallyResizedColumns[column.getColId()] = column.actualWidth;
        });
        setManuallyResizedColumns(newManuallyResizedColumns);
        saveGridState(newManuallyResizedColumns);
      }
    },
    [manuallyResizedColumns, saveGridState],
  );

  useEffect(() => {
    debouncedAutosizeRef.current = debounce((api) => {
      autoSizeAll({
        gridApi: api,
        skipHeader: autoSizeIgnoreHeaders,
        manuallyResizedColumns,
      });
    }, 300);

    return () => {
      if (debouncedAutosizeRef.current) {
        debouncedAutosizeRef.current.cancel();
      }
    };
  }, [autoSizeIgnoreHeaders, manuallyResizedColumns]);

  /**
   * Handles the grid size changed event. Reruns autosizing while respecting manually resized columns
   * @param {Object} params - The event parameters
   */
  const onGridSizeChanged = useCallback(
    (params) => {
      if (
        autoSizeEnabled
        && sizingRestored
        && params?.clientWidth
        && params?.clientHeight
      ) {
        debouncedAutosizeRef.current(params.api);
      }
    },
    [autoSizeEnabled, sizingRestored],
  );

  /**
   * Resets the grid filters and sorts
   */
  const resetGridFilters = useCallback(() => {
    try {
      if (!gridApi) return;
      gridApi.setFilterModel(null);
      saveGridState(manuallyResizedColumns);
    } catch (error) {
      console.error(`Error resetting ${gridId} grid filters:`, error);
    }
  }, [gridApi, gridId, manuallyResizedColumns, saveGridState]);

  /**
   * Resets column order, size, and visibility to default
   */
  const resetGridColumns = useCallback(() => {
    if (!gridApi) return;
    gridApi.resetColumnState();
    // gridApi.setColumnDefs(initialColumnDefs);
    setManuallyResizedColumns({});
    if (autoSizeEnabled) {
      autoSizeAll({ gridApi, skipHeader: autoSizeIgnoreHeaders });
    }
    saveGridState({});
  }, [
    gridApi,
    autoSizeEnabled,
    autoSizeIgnoreHeaders,
    saveGridState,
    // initialColumnDefs,
  ]);

  /**
   * Expands all grid columns to fit their contents
   */
  const expandGridColumns = useCallback(() => {
    try {
      // TODO: check how suppress auto sizing on manual resize interacts with this
      if (!gridApi) throw new Error("Grid API not found");
      autoSizeAll({ gridApi });
      saveGridState({});
    } catch (error) {
      console.error(`Error expanding ${gridId} grid columns:`, error);
    }
  }, [gridApi, gridId, saveGridState]);

  useEffect(() => {
    debouncedFitColumnsRef.current = debounce(() => {
      if (!gridApi) return;
      gridApi.sizeColumnsToFit({ defaultMinWidth: 80 });
    }, 300);

    return () => {
      if (debouncedFitColumnsRef.current) {
        debouncedFitColumnsRef.current.cancel();
      }
    };
  }, [gridApi]);

  /**
   * Fits grid columns to available width
   */
  const fitGridColumns = useCallback(() => {
    if (!gridApi) return;
    setManuallyResizedColumns({});
    saveGridState({});
    debouncedFitColumnsRef.current();
  }, [gridApi, saveGridState]);

  return {
    onGridReady,
    onFirstDataRendered,
    onColumnResized,
    onGridSizeChanged,
    onColumnMoved: handleSaveStateChange,
    onColumnVisible: handleSaveStateChange,
    onColumnPinned: handleSaveStateChange,
    onSortChanged: handleSaveStateChange,
    onFilterChanged: handleSaveStateChange,
    resetGridColumns,
    resetGridFilters,
    expandGridColumns,
    fitGridColumns,
    manuallyResizedColumns, // Expose this if needed elsewhere
  };
};
