import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import { showToast } from "components/Toasts/helpers/showToast";

export const RemoveAssignmentButton = ({ assignment_id }) => {
  const { data: assignment } = apiSlice.useGetAssignmentQuery({
    _id: assignment_id,
  });
  const [removeAssignment, { isLoading }]
    = apiSlice.useRemoveAssignmentMutation();
  const navigate = useNavigate();

  let canDelete = true;
  const cantDeleteReasons = [];
  if (assignment?.paidBalance !== 0) {
    canDelete = false;
    cantDeleteReasons.push("Paid balance is not 0.");
  }
  if (assignment?.availableBalance !== 0) {
    canDelete = false;
    cantDeleteReasons.push("Available (withdrawable) balance is not 0.");
  }
  if (assignment?.milestones?.length > 0) {
    const completedMilestones = assignment.milestones.filter(
      (milestone) => milestone.isComplete,
    );
    if (completedMilestones.length > 0) {
      canDelete = false;
      cantDeleteReasons.push("Has completed milestones.");
    }
  }

  const onRemove = useCallback(async() => {
    try {
      if (!canDelete) throw new Error("Unable to delete assignment");
      if (!assignment?._id) throw new Error("No assignment._id to remove");
      const campaign_id
        = assignment?.campaign_id?._id || assignment?.campaign_id;
      if (!campaign_id) throw new Error("campaign_id not found on assignment");
      const user_id = assignment?.user_id?._id || assignment?.user_id;
      if (!user_id) throw new Error("user_id not found on assignment");
      const confirmPrompt = `Are you sure you want to remove ${
        assignment?.user_id?.name || "this creator"
      } from ${assignment?.campaign_id?.name || "this campaign"}?`;
      if (!window.confirm(confirmPrompt)) {
        return;
      }
      await removeAssignment({ _id: assignment._id }).unwrap();
      showToast({
        type: "success",
        message: "Assignment successfully removed.",
      });
      navigate("/assignments");
    } catch (error) {
      console.error("Error removing assignment: ", error);
      showToast({
        type: "error",
        message: "Error removing assignment.",
      });
    }
  }, [
    assignment?._id,
    assignment?.campaign_id,
    assignment?.user_id,
    canDelete,
    navigate,
    removeAssignment,
  ]);

  return (
    <div className="remove-assignment-button">
      <Button
        color="danger"
        outline
        className="d-flex m-1 align-items-center remove-assignment-button__button"
        disabled={!canDelete || isLoading}
        onClick={onRemove}
      >
        <XMarkIcon height={20} width={20} className="me-2" />
        {isLoading ? "Working..." : "Remove"}
      </Button>
      {!canDelete && (
        <div className="remove-assignment-button__errors">
          <span>{"Can't remove assignment:"}</span>
          {cantDeleteReasons.length > 0 ? (
            <>
              {cantDeleteReasons.map((reason, index) => (
                <span key={index}>{reason}</span>
              ))}
            </>
          ) : (
            <>
              <span>
                Assignments with a paid balance, available balance, or completed
                milestones cannot be removed.
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
