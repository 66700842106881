import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { showToast } from "components/Toasts/helpers/showToast";
import { campaignDataDefs } from "features/campaigns/constants/campaignDataDefs";

import { CreateCampaignFields } from "./CreateCampaignFields";

// Get the field definitions for the campaign details form.
const fieldDefs = campaignDataDefs({})?.body;

/**
 * @typedef {Object} CreateCampaignModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} [initialCampaignName] - Initial campaign name
 * @property {object} [initialBrand_id] - Initial brand ID
 */
/**
 * Create Campaign Modal
 * @param {CreateCampaignModalProps} props
 * @returns {JSX.Element}
 */
export const CreateCampaignModal = ({
  modalOpen,
  toggleModal,
  initialCampaignName = undefined,
  initialBrand_id,
  ...props
}) => {
  const navigate = useNavigate();
  const [createCampaign, { isLoading }] = apiSlice.useCreateCampaignMutation();

  const onSubmit = useCallback(
    async(values) => {
      if (!values.enableAccountSegmentation) {
        // HACK: // ! hacky override for when shortCode is entered and then later dynamically hidden. Hidden field values should not be included in submit values but this fixes it for now.
        delete values.shortCode;
      }
      try {
        const newCampaign = await createCampaign(values).unwrap();
        toggleModal();
        if (newCampaign?._id) {
          navigate(`/campaigns/${newCampaign?._id}`);
        }
      } catch (error) {
        showToast({
          type: "error",
          message: `Error updating campaign. ${error?.data?.message || error?.message}`,
        });
        console.error(error);
      }
    },
    [createCampaign, navigate, toggleModal],
  );

  const initialValues = getInitialValues(fieldDefs, {
    name: initialCampaignName,
    brand_id: initialBrand_id,
  });

  const validate = useCallback((values) => {
    const errors = {};

    fieldDefs?.forEach((field) => {
      if (field?.editing?.required && !values[field?.fieldKey]) {
        errors[field?.fieldKey] = `Required`;
      }
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.status) {
        errors.status = "Required";
      }
      if (!values.brand_id) {
        errors.brand_id = "Required";
      }
      if (values?.department === "talent") {
        if (!values.enableAccountSegmentation) {
          errors.enableAccountSegmentation = "Required";
          errors.department
            = "Account segmentation is required for Talent campaigns";
        }
      }
      if (values?.enableAccountSegmentation) {
        if (!values.department) {
          errors.department = "Required";
        }
        if (!values.shortCode) {
          errors.shortCode = "Required";
        }
      }
      if (values.shortCode && !/^[a-zA-Z0-9]{3}$/.test(values.shortCode)) {
        errors.shortCode
          = "Short code must be exactly 3 characters and contain only letters and numbers";
      }
    });

    return errors;
  }, []);

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {isLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Create Campaign</ModalHeader>
          <ModalBody>
            <CreateCampaignFields
              fieldDefs={fieldDefs}
              initialValues={initialValues}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};
