import { useState } from "react";

import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useGetAgreementsQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { showToast } from "components/Toasts/helpers/showToast";
import { Agreement } from "features/agreements/components/Agreement";
import { agreementsColDefs } from "features/agreements/constants/colDefs";

import "features/agreements/index.scss";

const AgreementsGrid = ({
  assignment_id = null,
  creator_id = null,
  campaign_id = null,
  create = true,
  ...props
}) => {
  const [activeAgreementId, setActiveAgreementId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // TODO: optionally filter by creator, campaign, or assignment
  const filter = {};
  if (creator_id) filter.user_id = creator_id;
  if (campaign_id) filter.campaign_id = campaign_id;
  if (assignment_id) filter.assignment_id = assignment_id;
  const {
    data: agreements,
    error,
    isLoading,
    isFetching,
  } = useGetAgreementsQuery(filter);

  // useEffect(() => {
  //   refetch();
  // }, [isOpen, refetch]);

  if (error && !isLoading) {
    showToast({
      type: "error",
      message: "Error fetching agreements.",
    });
  }

  const onClickViewAgreement = ({ _id }) => {
    setActiveAgreementId(_id);
    setIsOpen(true);
  };

  const onClickNewAgreement = () => {
    setActiveAgreementId(null);
    setIsOpen(true);
  };

  return (
    <>
      {create && (
        <Button
          color="dark"
          className="d-flex align-items-center position-absolute create-agreements-button mt-2 me-2"
          onClick={onClickNewAgreement}
        >
          <PlusIcon height={20} width={20} className="me-2" />
          New Agreement
        </Button>
      )}
      <DataGrid
        idField="_id"
        gridId="agreements"
        borders
        columnDefs={agreementsColDefs}
        rowData={agreements}
        isLoading={isLoading}
        isFetching={isFetching}
        actionClick={onClickViewAgreement}
        actionText={"View"}
        quickFilter={true}
        appliedFilters={true}
        autoSizeIgnoreHeaders={false}
        {...props}
      />
      <Agreement
        agreementId={activeAgreementId}
        assignmentId={assignment_id}
        creatorId={creator_id}
        setAgreementId={setActiveAgreementId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AgreementsGrid;
